var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.777.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/undefined";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { ignoreErrors } from 'sentry.common.config';

const {
  publicRuntimeConfig: {
    sentry: { dsn }
  }
} = getConfig();

Sentry.init({
  dsn,
  tracesSampleRate: 0,
  sampleRate: 0.25,
  ignoreErrors
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
